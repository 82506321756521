import React, { useState } from 'react';
import samQuestions from '../SamForm/questions.json';
import CommonFormPage from '../CommonFormPage/CommonFormPage';
import { Question } from '../Question';
import { useQueryParam } from 'use-query-params';

const SamForm = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState<Question[]>(samQuestions as Question[]);

  const [code]: [string, unknown] = useQueryParam('code');

  const question = currentQuestionIndex >= questions.length ? null : questions[currentQuestionIndex] as Question;

  return (
    <CommonFormPage lang="en" type="cam" title="SAM Form" code={code}
      questions={questions} setQuestions={setQuestions}
      questionInfo="Please consider your supervision sessions over the past two months as you complete the following items."
      currentQuestionIndex={currentQuestionIndex} setCurrentQuestionIndex={setCurrentQuestionIndex}
      question={question ?? undefined} />
  );
};

export default SamForm;
