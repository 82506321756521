import React from 'react';
import { useQueryParam } from 'use-query-params';

const WrongCodePage = () => {
  const [lang]: [string, unknown] = useQueryParam('lang');

  return (
    <h1>{lang === 'no' ? 'Feil kode' : 'Wrong code'}</h1>
  );
};

export default WrongCodePage;
