import React from 'react';
import { useQueryParam } from 'use-query-params';

const SdqIntroPage = () => {
  const [code] = useQueryParam('code');

  return (
    <div>
      <h1>Velkommen til NUBU-skjemaer</h1>
      <p>Her er det litt intro-tekst</p>
      <a href={`/sdq?code=${code}`}>SDQ norsk</a>
    </div>
  );
};

export default SdqIntroPage;
