import React, { useEffect, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AnswerType, Question } from '../Question';
import CommonFormPage, { getQuestionById, updateQuestionsWithAnswer } from '../CommonFormPage/CommonFormPage';
import sdqQuestions from './questions.json';
import { useNavigate } from 'react-router-dom';

export interface SdqQuestion {
  id: string;
  question: SdqQuestionText;
  answerType: AnswerType;
  ifAnyQuestionOptionChosen?: [string, number[]];
  ifPhase?: string;
  options?: string[];
  chosenIndex?: number;
}

interface SdqQuestionText {
  subject: string; // Question for subject (child)
  parent: string; // Question for parent
}

type RespondentType = 'subject' | 'parent';
type Phase = 'before' | 'after';
type Method = 'mst' | 'rft' | 'tfco';

const SdqForm = () => {
  const navigate = useNavigate();

  // Example codes: s-b-mst-rAnDoM, p-b-rft-RaNdOm, s-a-tfco-rANdoM
  const parseCode = (code: string): [RespondentType, Phase, Method, boolean] => {
    const codeParts = code.split('-');
    const [respondentType, phase, method] = codeParts;
    const isValidCode = codeParts.length === 4 &&
      ['s', 'p'].includes(respondentType) &&
      ['b', 'a'].includes(phase) &&
      ['mst', 'rft', 'tfco'].includes(method);
    return [respondentType === 's' ? 'subject' : 'parent', phase === 'b' ? 'before' : 'after', method as Method, isValidCode];
  };

  const sdqQuestionsToQuestions = () => {
    return (sdqQuestions as SdqQuestion[])
      .filter((sdqQuestion) => !sdqQuestion.ifPhase || sdqQuestion.ifPhase === phase)
      .map((sdqQuestion) => ({
        ...sdqQuestion,
        question: sdqQuestion.question[respondentType].replaceAll('<method>', method.toUpperCase()),
        chosenIndex: -1,
      }));
  };

  const onQuestionSubmit = (answer: number) => {
    mostRecentlyAnsweredQuestion = { ...question, chosenIndex: answer } as Question;
    setQuestions(updateQuestionsWithAnswer(questions, question!.id, answer) as Question[]);
    setQuestions(getQuestionsToBeAnswered());
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const getQuestionsToBeAnswered = () => {
    return (sdqQuestionsToQuestions() as Question[]) // Start with all questions in case some questions that weren't included previously should be included now
      .filter((q) => shouldShowQuestion(q))
      .map((q) => ({ ...q, chosenIndex: getQuestionById(questions, q.id)?.chosenIndex ?? -1 }));
  };

  const shouldShowQuestion = (question: Question) => {
    const [decidingQuestionId, allowedAnswersToDecidingQuestion]
      = (question.ifAnyQuestionOptionChosen ?? ['', []]) as [string, number[]];
    if (!decidingQuestionId) {
      return true;
    }

    const decidingQuestionAnswer = mostRecentlyAnsweredQuestion?.id === decidingQuestionId
      ? mostRecentlyAnsweredQuestion!.chosenIndex
      : getQuestionById(questions, decidingQuestionId)?.chosenIndex ?? -1;
    return [...allowedAnswersToDecidingQuestion, -1].includes(decidingQuestionAnswer!);
  };

  const [code]: [string, unknown] = useQueryParam('code');
  const [respondentType, phase, method, isValidCode] = parseCode(code);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState<Question[]>(sdqQuestionsToQuestions());

  const question = currentQuestionIndex >= questions.length ? null : questions[currentQuestionIndex];
  let mostRecentlyAnsweredQuestion: Question | null = null;

  const questionInfo = question?.answerType === 'correctness-3'
    ? 'Vennligst velg hvor godt utsagnet under stemmer. Svar på grunnlag av barnets oppførsel <strong>den siste måneden</strong>.'
    : '';

  useEffect(() => {
    if (!isValidCode) {
      navigate('/wrong-code?lang=no');
    }
  }, [isValidCode, navigate]);

  return (
    <CommonFormPage lang="no" type="sdq" code={code} title="Sterke og svake sider (SDQ-Nor)" questions={questions}
      onQuestionSubmit={onQuestionSubmit} questionInfo={questionInfo}
      currentQuestionIndex={currentQuestionIndex} setCurrentQuestionIndex={setCurrentQuestionIndex}
      question={question ? { ...question, chosenIndex: question.chosenIndex ?? -1 } : undefined}
    />
  );
};

export default SdqForm;
