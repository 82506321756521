import React from 'react';
import { Form, Formik } from 'formik';
import { Button, FormGroup } from 'reactstrap';
import './Options.scss';
import { AnswerType } from '../../Question';

interface OptionsQuestionProps {
  question: string;
  type?: AnswerType;
  options?: string[];
  onSubmit: (answerIndex: number) => void;
  chosenIndex: number;
}

const OptionsQuestion: React.FC<OptionsQuestionProps> = ({
  question,
  type,
  options,
  onSubmit,
  chosenIndex,
}) => {
  switch (type) {
  case 'correctness-3':
    options = ['Stemmer ikke', 'Stemmer delvis', 'Stemmer helt'];
    break;
  case 'agreement-4':
    options = ['Ikke i det hele tatt', 'Bare litt', 'En god del', 'Mye'];
    break;
  case 'never-to-almost-always-5':
    options = ['Never', 'Rarely', 'Sometimes', 'Often', 'Almost always'];
    break;
  case 'never-to-always-6':
    options = ['Never', 'Rarely', 'Sometimes', 'Usually', 'Almost always', 'Always'];
    break;
  case 'never-to-weekly-5':
    options = ['Never', 'Once', 'Twice', '3-5 Times', 'Weekly'];
    break;
  case 'not-very-to-extremely-5':
    options = ['Not very', 'Somewhat', 'Moderately', 'Very', 'Extremely'];
    break;
  case 'not-at-all-to-extremely-6':
    options = ['Not at all', 'A little', 'Somewhat', 'Quite', 'Very', 'Extremely'];
    break;
  }
  return (
    <Formik
      initialValues={{ chosenIndex }}
      onSubmit={(values, actions) => {
        onSubmit(values.chosenIndex);
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <Form className="border border-primary border-3 rounded-4 p-4 mt-2">
          <p><strong>{question}</strong></p>
          <FormGroup>
            {options!.map((option, index) => (
              <Button type="button" color="success" key={index}
                className={`d-block my-2 ${chosenIndex === index ? 'active' : ''}`}
                style={{ width: '200px' }}
                onClick={() => {
                  formikProps.setFieldValue('chosenIndex', index);
                  formikProps.submitForm();
                }}>{option}</Button>
            ))}
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

export default OptionsQuestion;
