import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SdqIntroPage from './forms/SdqForm/SdqIntroPage';
import SdqForm from './forms/SdqForm/SdqForm';
import './App.scss';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import SamForm from './forms/SamForm/SamForm';
import CamForm from './forms/CamForm/CamForm';
import WrongCodePage from './WrongCodePage/WrongCodePage';

const App = () => {
  return (
    <div className="main">
      <Router>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Routes>
            <Route path="/sdq-intro" Component={SdqIntroPage} />
            <Route path="/sdq" Component={SdqForm} />
            <Route path="/sam" Component={SamForm} />
            <Route path="/cam" Component={CamForm} />
            <Route path="/wrong-code" Component={WrongCodePage} />
          </Routes>
        </QueryParamProvider>
      </Router>
    </div>
  );
};

export default App;
