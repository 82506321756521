import React, { useState } from 'react';
import camQuestions from './questions.json';
import { Question } from '../Question';
import CommonFormPage from '../CommonFormPage/CommonFormPage';
import { useQueryParam } from 'use-query-params';

const CamForm = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState<Question[]>(camQuestions as Question[]);

  const [code]: [string, unknown] = useQueryParam('code');

  const question = currentQuestionIndex >= questions.length ? null : questions[currentQuestionIndex] as Question;

  return (
    <CommonFormPage lang="en" type="cam" code={code}
      title="Multisystemic Therapy Institute Consultant Adherence Measure"
      questions={questions} setQuestions={setQuestions}
      questionInfo={question?.answerType === 'never-to-always-6' ? 'For this question, please think about your last MST consultation session' : ''}
      currentQuestionIndex={currentQuestionIndex} setCurrentQuestionIndex={setCurrentQuestionIndex}
      question={question ?? undefined} />
  );
};

export default CamForm;
