import React, { useState } from 'react';
import { Button } from 'reactstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Question } from '../Question';
import OptionsQuestion from '../questionTypes/Options/Options';
import axios from 'axios';

interface CommonFormProps {
  lang: 'no' | 'en';
  type: 'sdq' | 'sam' | 'cam';
  code: string;
  title: string;
  questionInfo: string;
  question: Question | undefined;
  questions: Question[];
  setQuestions?: React.Dispatch<React.SetStateAction<Question[]>>;
  currentQuestionIndex: number;
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  onQuestionSubmit?: (answer: number) => void;
}

export const getQuestionById = (questions: Question[], id: string) => {
  return questions.find((question) => question.id === id);
};

export const updateQuestionsWithAnswer = (questions: Question[], id: string, answerIndex: number) => {
  const q = getQuestionById(questions, id);
  if (!q) {
    return [...questions, { questionId: id, answer: answerIndex }];
  }
  q.chosenIndex = answerIndex;
  return [...questions];
};


const CommonFormPage: React.FC<CommonFormProps> = ({
  lang,
  type,
  code,
  title,
  questionInfo,
  question,
  questions,
  setQuestions,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  onQuestionSubmit
}) => {
  const texts = {
    previousQuestion: { no: 'Forrige spørsmål', en: 'Previous question' },
    nextQuestion: { no: 'Neste spørsmål', en: 'Next question' },
    completed: { no: 'Du har svart på alle spørsmålene.', en: 'You have answered all the questions.' },
    submit: { no: 'Send inn', en: 'Submit' },
    submitError: { no: 'Det skjedde en feil, vennligst prøv igjen', en: 'An error occurred, please try again' },
    thanks: { no: 'Takk for dine svar!', en: 'Thank you for taking the time to complete this survey.' },
  };

  const [isSentToBackend, setIsSentToBackend] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleQuestionSubmit = (answer: number) => {
    console.log(`Submitted answer for question ${currentQuestionIndex}:`, answer);
    if (onQuestionSubmit) {
      onQuestionSubmit(answer);
    } else {
      if (setQuestions) {
        setQuestions(updateQuestionsWithAnswer(questions, question!.id, answer) as Question[]);
      }
      gotoNextQuestion();
    }
  };

  const gotoNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const gotoPreviousQuestion = () => {
    setErrorMessage('');
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const sendToBackend = async () => {
    try {
      await axios.post('/api/save-form', { type, code, answers: getAnswers() }, {
        headers: { 'Content-Type': 'application/json' },
      });
      setErrorMessage('');
      setIsSentToBackend(true);
    } catch (error) {
      setErrorMessage(texts.submitError[lang]);
      console.error('Error:', error);
    }
  };

  const getAnswers = () => {
    const questionsMap: { [questionId: string]: number } = {};
    questions.forEach((q) => (questionsMap[q.id] = q.chosenIndex!));
    return questionsMap;
  };

  const percentCompleted = Math.round(100 * currentQuestionIndex / questions.length);

  return (
    <>
      <h1>{title}</h1>

      {
        question &&
        <p dangerouslySetInnerHTML={{ __html: questionInfo }}></p>
      }
      {
        !isSentToBackend && currentQuestionIndex > 0 &&
        <ProgressBar now={percentCompleted} label={percentCompleted >= 5 ? percentCompleted + '%' : ''} />
      }
      {
        isSentToBackend &&
        <h2 className="mt-3">{texts.thanks[lang]}</h2>
      }
      {
        question && question.answerType !== 'custom-options' &&
        <OptionsQuestion
          question={question.question}
          type={question.answerType}
          chosenIndex={question.chosenIndex!}
          onSubmit={handleQuestionSubmit}
        />
      }
      {
        question && question.answerType === 'custom-options' &&
        <OptionsQuestion question={question.question} options={question.options!}
          chosenIndex={question.chosenIndex!} onSubmit={handleQuestionSubmit}></OptionsQuestion>
      }
      {
        question && currentQuestionIndex !== 0 &&
        <Button type="button" color="warning" className="mt-3" onClick={gotoPreviousQuestion}>
          &larr; {texts.previousQuestion[lang]}
        </Button>
      }
      {
        !question && !isSentToBackend &&
        <>
          <p>{texts.completed[lang]}</p>
          <Button type="button" color="warning" className="me-3" onClick={gotoPreviousQuestion}>
            &larr; {texts.previousQuestion[lang]}
          </Button>
          <Button type="button" color="success" onClick={sendToBackend}>
            {texts.submit[lang]}
          </Button>
        </>
      }
      {
        question && question.chosenIndex !== undefined && question.chosenIndex !== -1 &&
        <Button type="button" color="warning" className="mt-3 float-end" onClick={gotoNextQuestion}>
          {texts.nextQuestion[lang]} &rarr;
        </Button>
      }
      {
        errorMessage &&
        <div className="mt-4 alert alert-danger" role="alert">{errorMessage}</div>
      }
    </>
  );
};

export default CommonFormPage;
